.Chat {
    display: flex;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    flex-wrap: wrap;    
    /* border-color: red; */
    /* height: 92vh; */
    max-height: 92vh;
    position: absolute;
    top: 0;
    overflow-y: auto;
}

.Fade {
    position: absolute;
    bottom: 8vh;

    display: block;
  
    width: 100%;
    height: 50px;
  
    background-image: linear-gradient(to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(27, 31, 35, 1)
    100%);
}



.Chat::-webkit-scrollbar {
    width: 5px;
}
.Chat::-webkit-scrollbar-track { 
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #282c34;
}

.Chat::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #8d929e; 
}




@media only screen and (max-width: 650px) {
    .Fade {
        bottom: 5vh;
    }

    .Chat {
        max-height: calc(89vh - 76px);
    }
  }

