.html {
    position: relative;
}

.EntryForm {
    width: 65%;
    min-width: 600px;
    height: auto;
    display: flex;
    flex-direction: row;
    border-style: none;
    border-radius: 10px;
    background-color: #272A31;
    box-shadow: 0 0 10px #1f212d;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 3vh 0px; 
    overflow: hidden;
    position: absolute;
    /* position: sticky; */
    bottom: 0;
    margin-bottom: 15px;
    /* position: fixed;
    bottom: env(safe-area-inset-bottom); */
}

/* .EntryForm:focus-within {
    outline: 1px solid #677084;
} */

.UserInput {
    display: flex;
    /* flex-grow: 2; */
    color: #FAF9F6;
    background-color: #272A31;
    border-style: none;
    border-radius: 0px;
    width: 100%;
    line-height: 1.42;
    margin: 12px 0px 12px 12px;
    padding: 0px 40px 0px 0px;
    font-family: 'Noto Serif Georgian';
    letter-spacing: 0.04rem;
    font-weight: 200;
    resize: none;
    height: 20px;
    max-height: 175px;
    overflow-y: scroll;
    z-index: 1;
    font-size: 13px;
}

.UserInput::placeholder {
    color: #b9babc;
}

textarea::-webkit-scrollbar {
    width: 5px;
}
textarea::-webkit-scrollbar-track { 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #848b9d; 
}

.UserInput:focus {
    outline: none;
}

.Button {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 10px 8px 7.5px 0px;
    font-family: 'Noto Serif Georgian';
    font-weight: 100;
    z-index: 2;
}

#VisibleButton:active,
#VisibleButton.active {
    border-style: double;
    border-width: 15px;
    border-color: #267426;
    transition: .1s;
}

#VisibleButton:hover{
    background-color: #0ea10e;
    transition: .165s;
}

#VisibleButton {
    background-color: #267426;
    color: #91fa91;
    transition: 0.3s;
}

#HiddenButton {
    background-color: #272A31;
    transition: 0.5s;
}

#HiddenButton:focus {
    outline: none;
}

.CircularProgress {
    color: "success";
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    position: absolute;
}

.errorAlert {
    font-size: 11px;
    line-height: 1.9;
    padding-left: 20px;
    width: 96%;
}

/* 
#StopButton {
    background-color: #610000;
    color: #fcaeae;
} */


.errorCollapse {
    z-index: 4; 
    width: "67.5%"; 
    min-width: '620px'; 
    position: 'absolute'; 
    bottom: 10;
    left: 17.6%;
    display: 'flex';
    align-items: 'center';
}

.MuiAlert-message {
    display: flex;
    width: 90%;
    overflow: hidden;
    /* background-color: dodgerblue; */
}

.errorMessage {
    width: 100%;
    overflow: visible;
    position: relative;
    text-align: 'center';
    line-height: 1;
    height: 'auto';
    margin: 5px 0px 0px 10%;
    padding: 0px 0px 0px 0px;
    display: 'flex';
    overflow: 'visible';
    font-size: 15px;
    font-weight: 500;
    font-family: 'Noto Serif Georgian';
    /* border-style: solid; */
    /* background-color: blue; */
    overflow-x: 'hidden'
}

.errorMessageWithMenu {
    width: 100%;
    overflow: visible;
    position: relative;
    text-align: 'center';
    line-height: 1;
    height: 'auto';
    margin: 5px 0px 0px 15%;
    padding: 3px 0px 3px 0px;
    display: 'flex';
    overflow: 'visible';
    font-size: 12px;
    font-weight: 500;
    font-family: 'Noto Serif Georgian';
    /* border-style: solid; */
    /* background-color: blue; */
    overflow-x: 'hidden'
}

.errorImageWithMenu {
    position: 'absolute';
    top: -60%;
    left: 1%; 
    height: 120px;
    margin-left: 367;
}

.errorImage {
    position: absolute; 
    top: -105%; 
    left: 1%; 
    height: 120px; 
    margin-left: 367;
}

.errorImageWithMenu {
    position: absolute; 
    top: -95%; 
    left: 1%; 
    height: 120px; 
    margin-left: 367;
}

@media only screen and (max-width: 1230px) {
   
    .errorMessageWithMenu {
        font-size: 11px;
        margin-left: 15%;
    }
}
    

@media only screen and (max-width: 1200px) {
    .errorMessage {
        font-size: 13px;
        margin-left: 11%;
    }

    .errorMessageWithMenu {
        font-size: 10px;
        margin-left: 14%;
    }
}


@media only screen and (max-width: 1100px) {
    .EntryForm {
        width: 60%;
        min-width: 500px;
    }

    .errorImage {
        top: -60%;
    }

    .errorImageWithMenu {
        top: -58%;
    }

    .errorAlert {
        font-size: 11px;
        padding-left: 20px;
        width: 94%;
    }

    .errorMessage {
        margin-left: 13%;
        font-size: 15px;
    }

    .errorMessageWithMenu {
        width: 60%;
        margin-left: 30%;
        font-size: 13px;
    }
}

@media only screen and (max-width: 1000px) {
    .errorMessageWithMenu {
        width: 60%;
        margin-left: 30%;
        font-size: 12px;
    }
}

@media only screen and (max-width: 860px) {
    .EntryForm {
        width: 92%;
        min-width: 0;
    }

    .errorAlert {
        width: 96%;
        font-size: 0.38em;
        line-height: 2.5;
    }
    
    .errorMessage {
        margin-left: 13%;
        font-size: 14px;
    }

    .errorImage {
        top: -108%;
    }
}

@media only screen and (max-width: 790px) {
    .errorMessageWithMenu {
        margin-left: 32%;
        font-size: 10px;
    }

}

@media only screen and (max-width: 750px) {
    .errorMessage {
        margin-left: 15%;
        font-size: 12px;
    }
}

@media only screen and (max-width: 700px) {
    .errorMessageWithMenu {
        margin-left: 35%;
        font-size: 8.5px;
    }

   
}

@media only screen and (max-width: 670px) {
    .errorMessage {
        margin-left: 17%;
        font-size: 11px;
    }
}

@media only screen and (max-width: 650px) {
    /* .errorAlert {
        width: 93%;
        line-height: 1.4;
    } */

    .UserInput {
        line-height: 1.44;
    }

    .errorMessage {
        font-size: 15px;
        margin-left: 16%;
    }

    .errorImage {
        top: -60%;
    }
}

@media only screen and (max-width: 580px) {
    .errorMessage {
        margin-left: 20%;
    }
}

@media only screen and (max-width: 500px) {
    .errorMessage {
        margin-left: 26%;
        font-size: 12px;
    }
    .errorImage {
        top: -78%;
    }
}

@media only screen and (max-width: 420px) {
    .UserInput {
        line-height: 1.48;
    }

    .errorMessage {
        margin-left: 32%;
        font-size: 10px;
    }
    .errorImage {
        top: -90%;
        left: 0%;
    }
}

@media only screen and (max-width: 370px) {
    .errorMessage {
        width: 70%;
        font-size: 10px;
    }

    .errorImage {
        top: -90%;
    }
}

@media only screen and (max-width: 360px) {
    .errorMessage {
        width: 90%;
        font-size: 11px;
        margin-left: 10px;
    }

    .errorImage {
        display: none;
    }
}

