.MessageRequest {
    background-color: #1B1F23;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px 25px 0px;
    height: fit-content;
    /* border-style: solid;
    border-color: #15161e; */
}

.MessageResponse {
    background-color: #272A31;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px 25px 0px;
    /* border-style: solid;
    border-color: #15161e; */
}

.MessageResponse > p {
    position: relative;
    font-family: 'Noto Serif Georgian';
    font-size: 14px;
    line-height: 2.3;
    border-radius: 10px;
    letter-spacing: 0.04rem;
    font-weight: 300;
}

.MessageParagraph {
    display: inline;
    position: relative;
    text-align: left;
    align-items: flex-start;
    width: 55%;
    min-width: 1000px;
    min-height: 27.59px;
    white-space: pre-wrap;
    font-family: 'Noto Serif Georgian';
    font-weight: 300;
    font-size: 13px;
    line-height: 2.3;
    border-radius: 10px;
    letter-spacing: 0.04rem;
    margin: 0px 0px 0px 20px; 
    padding: 0px 0px 3px 0px; 
    overflow-x: auto;
    width: 5px;
}

.MessageParagraph::-webkit-scrollbar {
    height: 6px;
}
.MessageParagraph::-webkit-scrollbar-track { 
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #282c34;
}

.MessageParagraph::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #8d929e; 
}


.Avatar {
    box-shadow: 0 0 8px #15161e;
}

@media only screen and (max-width: 2200px) {
    .MessageParagraph {
        width: 55%;
        min-width: 700px;
    }
}

@media only screen and (max-width: 1100px) {
    .MessageParagraph {
        width: 51%;
        min-width: 450px;
    }
}

@media only screen and (max-width: 860px) {
    .MessageParagraph {
        width: 80%;
        min-width: 0px;
    }
    .MessageRequest {
        padding: 25px 0px 30px 0px;
    }
    .MessageResponse {
        padding: 25px 0px 30px 0px;
    }
}

p.typing {
    display: inline;
    position: relative;
  }
  
p.typing::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 1.5em; 
    background-color: white;
    margin-left: 2px;
    animation: blink 1s infinite;
    border-radius: 1px;
}
  
@keyframes blink {
    0%, 49% {
        opacity: 1;
    }
    50%, 100% {
        opacity: 0;
    }
}
  

