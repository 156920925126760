html, body {
  background-color: #1B1F23;
  overflow-y: auto;
  overflow-x: hidden;
}

.App {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1B1F23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: calc(100% - 290px);
  right: 0;
  position: absolute;
  transition-duration: 80ms;
}

.menu-open {
  width: 75vw;
}

.fabIcon {
  font-family: "Fredericka the Great";
  font-size: 3.5em; 
  line-height: -5px;
}

.App-link {
  color: #61dafb;
}

.amplify-button {
  color: white;
}

.amplify-label {
  font-weight: bold;
}

.amplify-input:focus {
  border-color: white;
}

.amplify-input::-webkit-input-placeholder {
  font-weight: bold;
}

.amplify-input::-moz-placeholder {
  font-weight: bold;
}

.amplify-input:-ms-input-placeholder {
  font-weight: bold;
}

.amplify-input:-moz-placeholder {
  font-weight: bold;
}

.amplify-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #1B1F23 inset;
  -webkit-text-fill-color: white !important;
}

.amplify-alert {
  color: white;
  background-color: #DC0101;
}

.amplify-alert__dismiss {
  background-color: #DC0101;
}


.guestLoginView {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-items: center;
  width: 100%;
}

.centerButton {
  width: 26em;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-items: center;
}

.forgotPasswordButton:hover {
  background-color: #2b3846;
}

input::placeholder {
  color: rgb(176, 176, 176);
}

[data-amplify-router] {
  border-bottom-left-radius: 10px;  
  border-bottom-right-radius: 10px;  
}

.amplify-field-group__control.amplify-button--primary:not(.guestLoginButton, .sign) {
  background-color: hsl(190, 100%, 20%);
}

.amplify-field-group__control.amplify-button--primary:not(.guestLoginButton, .sign):hover {
  background-color: hsl(190, 95%, 30%); 
}

.guestLoginButton {
  display: flex;
  background-color: #3e424b;
}

.guestLoginButton:hover {
  background-color: #575768;
}

.forgotPasswordButton {
  background-color: #1B1F23;
  text-decoration: underline;
  padding-top: 6px;
}

.forgotPasswordButton:hover {
  background-color: #2b3846;
}


/* .amplify-tabs-item[data-state="active"] {
  background-color: red;
} */

@media only screen and (max-width: 479.33px) {
  .centerButton {
    width: 290px;
  }
  
}

@media only screen and (max-width: 351.33px) {
  .centerButton {
    width: 81vw;
  }

  .guestLoginButton {
    min-width: 231px;
  }
}

@media only screen and (max-width: 649px) {
  html, body {
    overflow-y: hidden;
  }

  .App {
    overflow-y: hidden;
  }

  .App-header {
    min-height: -webkit-fill-available;
    box-sizing: border-box;
  }

  .fabIcon {
    font-size: 5em; 
  }
}

/* @media only screen and (max-width: 500px) {
  .fabIcon {
    font-size: 5em; 
  }
} */

