.cookwise-header {
    text-align: center;
    color: white;
    font-family: 'Fredericka the Great';
    font-size: 3em;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 10px;
    border-style: none;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Noto Serif Georgian';
    font-size: 0.8em;
    letter-spacing: 0.04rem;
    width: 100em;
    align-items: center;
    font-weight: 200;
}

.welcome-p {
    border-spacing: 12px;
    border-radius: 10px;
    display: flex;
    color: #FAF9F6;
    background-color: #272A31;
    line-height: 1.4;
    margin: 0px 0px 5px 0px;
    padding: 15px 15px 15px 15px;
}

.row-examples > div {
    border-radius: 10px;
    display: flex;
    color: #FAF9F6;
    background-color: #272A31;
    line-height: 1.4;
    margin: 5px 0px 5px 0px;
    padding: 12px 12px 12px 12px;
}

.welcome-message {
    width: 37em;
    text-align: left;
}

.row-examples {
    display: flex;
    flex-direction: column;
}

.transcriptionModeSwitch {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 160px;
    /* left: 25%; */
    align-self: center;
    height: 90px;
    margin-bottom: 50px;
}

.switch input { 
    display:none;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    border-radius: 10px;
    background-color: #349a34;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 100.5%;
    width: 60%;
    left: -2px;
    /* bottom: 4px; */
    background-color: #272A31;
    background-image: url('../public/images/fast-alien-head.png'); /* Add your first image path here */
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    transition: .4s;
    border-radius: 10px;
}
  
input:checked + .slider {
    background-color: #2196F3;  
}

input:checked + .slider:before {
    transform: translateX(70%);
    background-image: url('../public/images/accurate-alien-head.png'); /* Add your second image path here */
}


@media only screen and (max-width: 1150px) {
    .welcome-message {
        width: 25em;
        text-align: left;
    }
}

@media only screen and (max-width: 860px) {
    .welcome-message {
        width: 85%;
        text-align: left;
    }

    .welcome-container {
        font-size: 0.7em;
    }
}


@media only screen and (max-width: 650px) {
    .cookwise-header {
        font-size: 60px;
    }

    .welcome-message {
        margin-top: 5%;
        width: 86%;
    }

    .welcome-p {
        margin-top: 1%;
    }

}
